<template>
  <section class="video__center inner">
    <div class="news__title">
      <strong class="slidetop" v-animate-onscroll="'active'">{{videos.name}}</strong>
      <p class="slidetop detay1" v-animate-onscroll="'active'">{{videos.description}}</p>
    </div>
    <ul class="video__center--list slideList" v-animate-onscroll="'active'">
      <li v-for="item in videos.list" :key="item.cid" @click="videoSlide(item.video)">
        <figure><img :src="item.thumb" alt=""/><b class="iconfont">&#xe78d;</b></figure>
        <strong>{{item.title}}</strong>
      </li>
    </ul>
  </section>
</template>

<script>

import { useMyStore } from '../../store';

export default {
  props: {
    videos:{
      type: Object,
      required: true
    }
  },
  computed: {
    count() {
      return useMyStore().count;
    },
  },
  methods: {
    videoSlide(src) {
      useMyStore().setPopShow(src);
    },
  },
}
</script>

<style>

</style>