<template>
  <section class="business__boxs" >
    <div class="news__title">
      <strong class="slidetop" v-animate-onscroll="'active'">{{business.name}}</strong>
      <p class="slidetop detay1" v-animate-onscroll="'active'">{{business.description}}</p>
    </div>
    <ul class="business__list inner slideList" v-animate-onscroll="'active'">
      <li v-for="(item, index) in business.list" :key="index">
        <a target="_block" :href="item.path">
          <figure><img :src="item.thumb" alt=""/></figure>
          <strong>{{item.name}}</strong>
        </a>
      </li>
    </ul>
    <div class="news__mores">
      <router-link to='/business'>探索更多</router-link>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    business:{
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>