<template>
  <div>
    <Banner :banner="bannerList"/>
    <News :news="news"/>
    
    <Introduct :about="about"/>
    <Video :videos ="videos"/>
    <Business :business="business"/>
    <Logistics :logistics="logistics"/>
    <Partner :partner="partner"/>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer';
import Banner from '../components/home/Banner';
import News from '../components/home/Newsnew';
import Video from '../components/home/Video';
import Introduct from '../components/home/Introduct';
import Business from '../components/home/Business';
import Logistics from '../components/home/Logistics';
import Partner from '../components/home/Partner';

export default {
  components: {
    Banner,
    News,
    Video,
    Introduct,
    Business,
    Logistics,
    Partner,
    Footer
  },
  data() {
    return {
      bannerList:[],
      news: {},
      videos: {},
      about: {},
      business:{},
      logistics:[],
      partner: []
    }
  },
  methods: {
    async getHomeData(){
      // const {data: res1} = await this.$http.get("getIndexInfo");
      const {data: res} = await this.$http.get("getIndexInfoNew");
      this.bannerList = res.data.banner;
      this.news = res.data.news;
      this.videos = res.data.video
      this.about = res.data.about;
      this.business = res.data.business;
      this.logistics = res.data.server;
      this.partner = res.data.partner;
    }
  },
  mounted() {
    this.getHomeData();
  }
}
</script>

<style lang="less">

</style>