<template>
  <section class="news__dynamic">
    <div class="news__dynamic--wrap inner">
      <div class="news__title">
        <strong class="slidetop" v-animate-onscroll="'active'">{{news.name}}</strong>
        <p class="slidetop detay1" v-animate-onscroll="'active'">{{news.description}}</p>
      </div>
      <div class="news__flex">
        <div class="news__left slidetop" v-animate-onscroll="'active'">
          <div class="news__swiper">
            <swiper :options="swiperOption" ref="mySwiper" v-if='news.leftscroll && news.leftscroll.length >0'>
              <swiper-slide class="news-items" v-for="item in news.leftscroll" :key="item.cid">
                <router-link :to="'/companynews/'+item.cid">
                  <figure><img :src="item.thumb" alt=""/></figure>
                  <strong>{{item.title}}</strong>
                </router-link>
              </swiper-slide>
              <div class="swiper-pagination" v-if='news.leftscroll && news.leftscroll.length >1' slot="pagination"></div>
            </swiper>
          </div>
          <!-- <ul class="news__list">
            <li v-for="itemli in news.leftimg" :key="itemli.cid">
              <router-link :to="'/companynews/'+itemli.cid">
                <figure><img :src="itemli.thumb" alt=""/></figure>
                <p>{{itemli.title}}</p>
              </router-link>
            </li>
          </ul> -->
        </div>
        <div class="news__middle slidetop detay1" v-animate-onscroll="'active'">
          <ul class="news__middle--list">
            <li v-for="itemmiddle in news.middle" :key="itemmiddle.cid">
              <router-link :to="'/companynews/'+itemmiddle.cid">
                <div class="news__fixbox">
                  <strong>{{itemmiddle.title}}</strong>
                  <time>{{itemmiddle.create_time}}</time>
                  <p>{{itemmiddle.description}}</p>
                </div>
                <div class="news__images">
                  <figure><img :src="itemmiddle.thumb" alt=""/></figure>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- <div class="news__right slidetop detay2" v-animate-onscroll="'active'">
          <div class="news__info" v-if="news.right">
            <router-link :to="'/companynews/'+news.right[0].cid">
              <figure><img :src="news.right[0].thumb" alt=""/></figure>
              <div class="news__din">
                <strong>{{news.right[0].title}}</strong>
                <time>{{news.right[0].create_time}}</time>
                <p>{{news.right[0].description}}</p>
              </div>
            </router-link>
          </div>
          <div class="news__mores">
            <router-link to="/companynews/companynews">查看更多</router-link>
          </div>
        </div> -->
      </div>
      <div class="news__mores">
          <router-link to="/companynews/companynews">查看更多</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    swiper,
    swiperSlide
  },
  props: {
    news:{
        type: Object,
        required: true
    }
  },
  data() {
    return {
      swiperOption: {
        loop: false,
        speed:1000,
        parallax : true,
        autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false
        },
        observeParents:false,   
        observer:true,
        // 显示分页
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
}
</script>

<style>

</style>