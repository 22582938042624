<template>
    <div class="partner">
        <div class="partner-title" v-animate-onscroll="{down: 'animated fadeInUp'}">
            <strong>合作伙伴</strong>
            <p>客户至上，以品质缔造用心之选。强强联手，让专业创造更大价值。</p>
        </div>
        <ul class="partner-list" v-animate-onscroll="{down: 'animated fadeInUp'}">
            <li v-for="(item) in partner" :key="item.cid">
                <figure><img :src="item.thumb" :alt="item.title"/></figure>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['partner']
}
</script>

<style lang="less">
.partner{
    padding: 44px 9.375% 30px;
    background: #f5f5f5;
}
.partner-title{
    margin-bottom: 32px;
    text-align: center;
    strong{
        display: block;
        margin-bottom: 10px;
        line-height: 1.2;
        color: #333333;
        font-size: 40px;
        font-weight: normal;
    }
    p{
        color: #999999;
        font-size: 14px;
    }
}
.partner-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li{
        margin-right: 0.8%;
        margin-bottom: 10px;
        width: 16%;
        &:nth-child(6n+6),&:last-child{
            margin-right: 0;
        }
        figure{
            position: relative;
            padding-bottom: 110px;
            background: #fff;
            transition: 0.4s;
            &:hover{
                box-shadow: 0 0 11px rgba(0,0,0,0.1);
            }
            img{
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-height: 100%;
                max-width: 80%;
            }
        }
    }
}
</style>