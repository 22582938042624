<template>
  <section class="introduct__box">
    <div class="introduct__imgs slidetop" v-animate-onscroll="'active'">
      <figure><img :src="about.images" alt=""/></figure>
    </div>
    <div class="introduct__rights">
      <div class="introduct__wrs">
        <div class="introduct__tops slidetop" v-animate-onscroll="'active'">
          <p>{{about.sologn}}</p>
          <strong>{{about.title}}</strong>
        </div>
        <div class="introduct__in slidetop detay1" v-animate-onscroll="'active'">
          <p v-html="about.content"></p>
        </div>
        <div class="introduct__data slideRightList" v-animate-onscroll="'active'">
          <div class="introduct__data--item" v-for="items in about.canshu" :key="items.k">
            <strong><b><i-count-up class="counter-value" :endVal="parseInt(items.k)" :delay="0" :options="options"></i-count-up></b>+</strong>
            <p>{{items.v}}</p>
          </div>
        </div>
        <div class="introduct__downs slidetop" v-animate-onscroll="'active'">
          <div class="news__mores">
            <router-link to="/about/about">探索更多</router-link>
          </div>
          <div class="check__video" @click="videoSlide(about.video)">
            <i class="iconfont"></i>
            <span>观看企业视频</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ICountUp from 'vue-countup-v2'
import { useMyStore } from '../../store';
export default {
  data() {
    return {
      options: {
        duration: 1.4,
        useEasing: false,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
        enableScrollSpy: true
      },
    }
  },
  props: {
    about:{
      type: Object,
      required: true
    }
  },
  components: {
    ICountUp,
  },
  methods: {
    videoSlide(src) {
      useMyStore().setPopShow(src);
    },
  }
}
</script>

<style>

</style>